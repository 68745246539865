<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">
                  Liquidaciónes Internas {{ form ? form.id : "" }}
                </h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/cs">Carga Seca</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Formulario {{ accion }} Liquidaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="nombre">Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        placeholder="Nombre"
                        v-model="form.nombre"
                        :disabled="estadodis"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="bloque">Bloque</label>
                      <v-select
                        :class="[
                          $v.form.bloque_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="form.bloques"
                        placeholder="Bloque"
                        label="nombre"
                        :options="listasForms.bloques"
                        @search="buscarBloque"
                        @input="selectBloque()"
                        multiple
                        :disabled="estadodis"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="form-group">
                      <label for="empresa">Empresa</label>
                      <v-select
                        :class="[
                          $v.form.empresa_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="empresa"
                        placeholder="Seleccione Empresa..."
                        label="razon_social"
                        class="form-control form-control-sm p-0"
                        :options="listasForms.empresas"
                        @input="selectEmpresa()"
                        :disabled="estadodis"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="obs">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        id="obs"
                        cols="20"
                        rows="2"
                        v-model="form.observaciones"
                        :disabled="estadodis"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_ini">Fecha Inicio</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_ini"
                        :class="
                          $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="fecha_fin">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                        :class="
                          $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="estado">Estado</label>
                      <select
                        id="estado"
                        v-model="form.estado"
                        class="form-control form-control-sm"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="estadodis"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                          :disabled="
                            estado.numeracion === 2 &&
                              $route.params.accion == 'Crear'
                          "
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Seleccione para Liquidar</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-2"></div>
                  <div class="col-md-3">
                    <label for="tipo_servicio">Tipo Servicio</label>
                    <v-select
                      id="tipo_servicio"
                      v-model="form.tipo_servicio"
                      :class="[
                        $v.form.tipo_servicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      placeholder="Tipo Solicitud"
                      label="descripcion"
                      :options="listasForms.tipos_servicios"
                      @input="selectTipoServicio()"
                      :disabled="
                        form.fecha_ini == '' ||
                          form.fecha_fin == '' ||
                          estadodis
                      "
                      multiple
                    >
                    </v-select>
                  </div>
                  <div class="col-md-11 text-center m-2">
                    <h6 v-if="form.fecha_ini == '' || form.fecha_fin == ''">
                      <span class="badge badge-warning"
                        ><i class="fas fa-times-circle"></i> Recuerde que se
                        habilita el campo cuando esten llenas las fechas</span
                      >
                    </h6>
                  </div>
                  <div class="col-md-2"></div>
                </div>
                <div class="row">
                  <div
                    class="col-md-6"
                    v-if="
                      form.tipo_servicio.some((serv) => serv.numeracion == 1)
                    "
                  >
                    <div class="form-group">
                      <label for="tipo_vehiculo">Tipo Vehículo</label>
                      <div class="row">
                        <div class="col-md-10">
                          <v-select
                            id="tipo_vehiculo"
                            v-model="form.tipo_vehiculo"
                            :class="[
                              $v.form.tipo_vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            placeholder="Tipo Vehículo"
                            label="nombre"
                            :options="listasForms.tipos_vehiculos"
                            @input="selectTipoVeh()"
                            multiple
                            :disabled="estadodis"
                          >
                          </v-select>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="
                            listasForms.tipos_vehiculos.length !=
                              form.tipo_vehiculo.length
                          "
                        >
                          <button
                            class="btn alert-default-info"
                            @click="seleccionarVehiculos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-list"></i>
                          </button>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="form.tipo_vehiculo.length > 0"
                        >
                          <button
                            class="btn alert-default-danger"
                            @click="limpiarVehiculos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="
                      form.tipo_servicio.some((serv) => serv.numeracion == 2)
                    "
                  >
                    <div class="form-group">
                      <label for="tipo_equipo">Tipo Equipo</label>
                      <div class="row">
                        <div class="col-md-10">
                          <v-select
                            :class="[
                              $v.form.tipo_equipo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.tipo_equipo"
                            placeholder="Tipo Equipo"
                            label="descripcion"
                            :options="listasForms.tipos_equipos"
                            multiple
                            @input="selectTipoEquipo()"
                            :disabled="estadodis"
                          >
                          </v-select>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="
                            listasForms.tipos_equipos.length !=
                              form.tipo_equipo.length
                          "
                        >
                          <button
                            class="btn alert-default-info"
                            @click="seleccionarEquipos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-list"></i>
                          </button>
                        </div>
                        <div
                          class="col-md-1"
                          v-if="form.tipo_equipo.length > 0"
                        >
                          <button
                            class="btn alert-default-danger"
                            @click="limpiarEquipos()"
                            :disabled="estadodis"
                          >
                            <i class="fas fa-broom"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer mt-1">
                <div class="row">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <button
                      class="btn bg-primary col-md-4"
                      v-if="
                        !$v.form.$invalid &&
                          $store.getters.can('cs.liquidacionesInternos.create')
                      "
                      @click="save()"
                      :disabled="estadodis"
                    >
                      <i class="fas fa-paper-plane"></i><br />Guardar
                    </button>
                    <button class="btn bg-secondary col-md-4" @click="back()">
                      <i class="fas fa-reply"></i><br />Volver
                    </button>
                    <button
                      v-if="liquidacion && liquidacion.id"
                      class="btn bg-indigo col-md-4"
                      @click="printTicket(liquidacion)"
                    >
                      <i class="fas fa-print"></i><br />Imprimir
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card"
              v-if="
                liquidacion &&
                  liquidacion.cs_liquidaciones_internas_firmas &&
                  liquidacion.cs_liquidaciones_internas_firmas.length > 0
              "
            >
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Aprobaciones</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div
                    class="col-md-4"
                    v-for="firma in liquidacion.cs_liquidaciones_internas_firmas"
                    :key="firma.id"
                  >
                    <div class="card bg-light d-flex flex-fill">
                      <div class="card-header text-muted border-bottom-0">
                        <h5>
                          {{
                            firma.tipo_firma == 1
                              ? "Firma Contratista"
                              : firma.tipo_firma == 2
                              ? "Firma Interventor del Contrato"
                              : firma.tipo_firma == 3
                              ? "Firma Administrador del Contrato"
                              : ""
                          }}
                        </h5>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12">
                            <p>
                              Firmado por: <br /><strong>{{
                                firma.user.name
                              }}</strong>
                            </p>
                            <p class="text-center mr-5 ml-5">
                              <img
                                :src="uri_docs + firma.user.link_firma"
                                class="img-fluid"
                              />
                            </p>
                            <p>
                              Fecha: <br /><strong>{{
                                firma.fecha_firma
                              }}</strong>
                            </p>
                            <p>
                              Observación: <br />
                              {{ firma.observacion }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card" v-if="form.id">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Resumen</h5>
              </div>
              <div class="card-body p-0">
                <div class="row m-2">
                  <table class="table table-bordered table-sm">
                    <tbody>
                      <template v-for="(det, indiceTip) in detLiquidacion">
                        <tr
                          data-widget="expandable-table"
                          aria-expanded="false"
                          class="bg-light"
                          :key="indiceTip"
                        >
                          <td style="font-size: 10px">
                            <div class="row">
                              <div class="col-sm-3 m-0">
                                <div class="header-resum">
                                  <h3 class="header-des text-bold text-sm">
                                    <i class="fas fa-truck"></i>
                                    {{
                                      det.tipEquipoVehiculo
                                        ? det.tipEquipoVehiculo
                                        : det.tipEquipoEquipo
                                    }}
                                    {{
                                      det.ltipo_remolque
                                        ? " - " + det.ltipo_remolque.descripcion
                                        : ""
                                    }}
                                  </h3>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="header-resum">
                                  <h3 class="header-des text-bold text-sm">
                                    <i class="fas fa-calculator"></i>
                                    Cant Servicios Prod/Mant:
                                    <span class="badge bg-info">
                                      {{ det.cant_servicios }}</span
                                    >
                                  </h3>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="header-resum">
                                  <h3 class="header-des text-sm text-bold">
                                    <i class="fas fa-dollar-sign"></i>
                                    Costo Total
                                    <span class="badge bg-info">
                                      {{ det.Total ? det.Total : "$ 0" }}</span
                                    >
                                  </h3>
                                </div>
                              </div>
                              <div class="col-sm-3">
                                <div class="header-resum">
                                  <h3 class="header-des text-bold text-sm">
                                    <i class="fas fa-dollar-sign"></i>
                                    Costo horas Adicionales:
                                    <span class="badge bg-info">
                                      {{
                                        det.costoAdicionalTot
                                          ? det.costoAdicionalTot
                                          : "$ 0"
                                      }}</span
                                    >
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="expandable-body d-none"
                          :key="indiceTip + 'a'"
                        >
                          <td>
                            <div class="card-body p-0">
                              <table
                                class="table table-bordered table-sm m-2"
                                style="font-size: 0.85em"
                              >
                                <thead
                                  class="bg-frontera text-white text-center"
                                >
                                  <tr>
                                    <th rowspan="2" class="pl-1">
                                      <label for="">Servicio</label>
                                      <input
                                        v-model="buscarResum.servicio"
                                        class="form-control form-control-sm"
                                        placeholder="N° Servicio"
                                      />
                                    </th>
                                    <th rowspan="2">
                                      <label for="">Vehículo / Equipo</label>
                                      <input
                                        v-model="buscarResum.vhEqu"
                                        class="form-control form-control-sm"
                                        placeholder="Placa"
                                      />
                                    </th>
                                    <th rowspan="2">
                                      <label for="">Documento Conductor</label>
                                      <input
                                        v-model="buscarResum.condApa"
                                        type="number"
                                        class="form-control form-control-sm"
                                        placeholder="Documento"
                                      />
                                    </th>
                                    <th rowspan="2">
                                      <label for="">Fecha Inicio</label>
                                      <input
                                        v-model="buscarResum.fechaIni"
                                        type="date"
                                        class="form-control form-control-sm"
                                      />
                                    </th>
                                    <th rowspan="2">
                                      <label for="">Fecha Fin</label>
                                      <input
                                        v-model="buscarResum.fechaFin"
                                        type="date"
                                        class="form-control form-control-sm"
                                      />
                                    </th>
                                    <th class="text-center" colspan="3">
                                      Reporte Trabajo
                                    </th>
                                    <th class="text-center" rowspan="2">
                                      Hora Adicional
                                    </th>
                                    <th class="text-center" rowspan="2">
                                      Movilización - Desmovilización
                                    </th>
                                    <th class="text-center" colspan="3">
                                      Tarifa
                                    </th>
                                  </tr>
                                  <tr class="text-center">
                                    <th>
                                      Operativo
                                      <small
                                        class="description-text text-secundary"
                                      >
                                        (Horas)
                                      </small>
                                    </th>
                                    <th>
                                      Disponible
                                      <small
                                        class="description-text text-secundary"
                                      >
                                        (Horas)
                                      </small>
                                    </th>
                                    <th>
                                      Fuera Operación
                                      <small
                                        class="description-text text-secundary"
                                      >
                                        (Horas)
                                      </small>
                                    </th>
                                    <th class="text center m-0">
                                      Costo Prod/Mant
                                    </th>
                                    <th class="text center m-0">Costo Add</th>
                                    <th class="text center m-0">Costo Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(resum,
                                    indiceResum) in filteredRowsDetail"
                                    :key="indiceResum"
                                    :class="'bg-light'"
                                  >
                                    <!-- # Servicio -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      {{ resum.id }}
                                    </td>
                                    <!-- Vehículo o Equipo -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      {{ resum.equipo.placa }}
                                    </td>
                                    <!-- Documento Conductor -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      {{ resum.persona.numero_documento }}
                                    </td>
                                    <!-- Fecha Inicial -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      {{ resum.fecha_ini }}
                                    </td>
                                    <!-- Fecha Final -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      {{ resum.fecha_fin }}
                                    </td>
                                    <!-- Horas Operativo -->
                                    <td
                                      class="text-center bg-lightgreen"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.operativo
                                            ? resum.operativo
                                            : "0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Horas Disponible -->
                                    <td
                                      class="text-center bg-lightyellow"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.disponible
                                            ? resum.disponible
                                            : "0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Horas Fuera Operación -->
                                    <td
                                      class="text-center bg-lightred"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.fuera_operacion
                                            ? resum.fuera_operacion
                                            : "0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Hora Adicional -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.adicionales
                                            ? resum.adicionales
                                            : "0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Mov/Des -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    ></td>
                                    <!-- Costo Trabajo -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.costoTrabajo
                                            ? resum.costoTrabajo
                                            : "$ 0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Costo Adicional -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.costoAdd
                                            ? resum.costoAdd
                                            : "$ 0"
                                        }}
                                      </strong>
                                    </td>
                                    <!-- Costo Total -->
                                    <td
                                      class="text-center"
                                      v-if="shouldShowCell(resum, det)"
                                    >
                                      <strong>
                                        {{
                                          resum.costoTotal
                                            ? resum.costoTotal
                                            : "$ 0"
                                        }}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-header pt-2 pb-2 bg-frontera text-white">
                <h5 class="mb-0">Centros costo</h5>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <button @click="getResumenCC(form.id)">Calcular</button>
                    <vue-pivottable-ui
                      :data="pivotData"
                      :aggregatorName="aggregatorName"
                      renderer-name="Table Heatmap"
                      :row-total="true"
                      :col-total="true"
                      :rows="['equipo']"
                      :vals="['valor_base_porc']"
                      :cols="['codigo_contable']"
                      ref="pvt"
                    >
                    </vue-pivottable-ui>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import { VuePivottableUi } from "vue-pivottable";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import "vue-pivottable/dist/vue-pivottable.css";
export default {
  name: "CsLiquidacionInternosForm",
  components: {
    vSelect,
    VuePivottableUi,
    Loading,
  },

  data() {
    return {
      id: null,
      accion: "",
      metodo: "",
      firma_admin: 0,
      cargando: false,
      estadodis: false,
      bloque: {},
      empresa: {},
      detLiquidacion: {},
      resumen: {},
      tarifaLiqui: {},
      cantTipos: {},
      liquidacion: {},
      form: {
        nombre: null,
        bloques: [],
        bloque_id: [],
        empresa_id: null,
        fecha_ini: "",
        fecha_fin: "",
        estado: null,
        tipo_servicio: [],
        tipo_servicio_id: [],
        tipo_vehiculo: [],
        tipo_vehiculo_id: [],
        tipo_equipo: [],
        tipo_equipo_id: [],
      },
      buscarResum: {
        servicio: "",
        vhEqu: "",
        condApa: "",
        fechaIni: "",
        fechaFin: "",
        turno: "",
      },
      listasForms: {
        bloques: [],
        empresas: [],
        estados: [],
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      form1: {},
      dataPivot: [],
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let tv = {
      required: false,
    };
    let te = {
      required: false,
    };

    if (this.form.tipo_servicio.find((t) => t.numeracion == 1)) {
      tv = {
        required,
      };
    }
    if (this.form.tipo_servicio.find((t) => t.numeracion == 2)) {
      te = {
        required,
      };
    }
    return {
      form: {
        nombre: {
          required,
        },
        bloque_id: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        empresa_id: {
          required,
        },
        estado: {
          required,
        },
        tipo_servicio: {
          required,
        },
        tipo_vehiculo: tv,
        tipo_equipo: te,
      },
    };
  },

  created() {
    this.seleccionesPrevias = [...this.form.tipo_servicio]; // Inicializa las selecciones previas
  },

  computed: {
    filteredRowsDetail() {
      if (this.resumen) {
        return this.resumen.filter((res) => {
          /* Resultado de res */
          const idServ = res.id.toString().toLowerCase();
          const vhEqui = res.equipo.placa.toLowerCase();
          const condApa = res.persona.numero_documento.toLowerCase();
          const fechaIni = res.fecha_ini.toLowerCase();
          const fechaFin = res.fecha_fin.toLowerCase();

          /* Constantes modelos de los inputs */
          const searchTermIdServ = this.buscarResum.servicio.toLowerCase();
          const searchTermvh = this.buscarResum.vhEqu.toLowerCase();
          const searchTermcondApa = this.buscarResum.condApa.toLowerCase();
          const searchFechaIni = this.buscarResum.fechaIni.toLowerCase();
          const searchFechaFin = this.buscarResum.fechaFin.toLowerCase();
          return (
            vhEqui.includes(searchTermvh) &&
            condApa.includes(searchTermcondApa) &&
            fechaIni.includes(searchFechaIni) &&
            fechaFin.includes(searchFechaFin) &&
            idServ.includes(searchTermIdServ)
          );
        });
      } else {
        return this.resumen;
      }
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.liquidacion = this.$route.params.data_edit;
      await this.buscarEmpresas();
      await this.getEstados();
      await this.getTipoSolicitud();

      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;

      if (this.$route.params.accion == "Editar") {
        this.estadodis =
          this.liquidacion.estado == 2
            ? true
            : !(await this.$store.getters.can("cs.liquidacionesInternos.edit"));

        this.form.nombre = this.liquidacion.nombre;
        this.form.empresa_id = this.liquidacion.empresa_id;
        this.form.observaciones = this.liquidacion.observaciones;
        this.form.fecha_ini = this.liquidacion.fecha_ini;
        this.form.fecha_fin = this.liquidacion.fecha_fin;
        this.form.estado = this.liquidacion.estado;
        this.form.id = this.liquidacion.id;
        this.form.bloques = this.liquidacion.bloques;
        let tipoServ = this.liquidacion.cs_liquidaciones_internas_tipo_serv;
        if (tipoServ) {
          let tipServSelect = [];
          await tipoServ.forEach(async (t) => {
            let find_servTip = this.listasForms.tipos_servicios.find(
              (l) => l.numeracion === t.tipo_servicio
            );

            if (find_servTip !== undefined) {
              let find_servTip2 = tipServSelect.find(
                (serv) => serv.numeracion === t.tipo_servicio
              );
              if (find_servTip2 === undefined) {
                tipServSelect.push(find_servTip);
              }
            }
          });
          this.form.tipo_servicio = tipServSelect;
          await this.selectTipoServicio();
        }

        let find_emp = this.listasForms.empresas.find(
          (e) => e.id === this.form.empresa_id
        );
        this.empresa = find_emp;
        this.form.bloque_id = this.form.bloques.map((bloque) => bloque.id);

        await this.liquidacion.cs_liquidaciones_internas_firmas.forEach(
          async (firma) => {
            if (firma.tipo_firma == 3) {
              this.firma_admin = 1;
              // await this.showFacturacion();
            }
          }
        );
        this.metodo = "PUT";
        this.getDetLiquidacion();
        this.cargando = false;
      } else {
        this.metodo = "POST";
        this.cargando = false;
      }
      this.cargando = false;
    },

    async getDetLiquidacion() {
      await axios
        .get("/api/cs/liquidacionesInternos/detLiquidacion", {
          params: {
            bloque_id: this.form.bloque_id,
            cs_liquidacion_interna_id: this.form.id,
            empresa_id: this.form.empresa_id,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        })
        .then(async (response) => {
          this.detLiquidacion = response.data.detLiquidacion;
          this.resumen = response.data.resumen;
          this.cantTipos = response.data.servTotal;
          this.tarifaLiqui = response.data.tarifaLiqui;
          for (let i = 0; i < this.resumen.length; i++) {
            let costoTrabajo = 0;
            let costoAdd = 0;
            let costoTotal = 0;
            let resumen = this.resumen[i];
            /* Tarifas Servicios */
            for (let k = 0; k < resumen.tarifas.length; k++) {
              let valor_base = resumen.tarifas[k].valor_base;
              if (
                resumen.tarifas[k].cs_det_tarifa_type ==
                "App\\CsDetTarifasTrabajos"
              ) {
                costoTrabajo = valor_base;
                this.resumen[i].costoTrabajo = this.formatCurrency(
                  "es-CO",
                  "COP",
                  2,
                  costoTrabajo
                );
              } else {
                costoAdd = valor_base;
                this.resumen[i].costoAdd = this.formatCurrency(
                  "es-CO",
                  "COP",
                  2,
                  costoAdd
                );
              }

              costoTotal = parseFloat(costoTrabajo) + parseFloat(costoAdd);

              this.resumen[i].costoTotal = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                costoTotal
              );
            }
            /* Detalles Servicios */
            this.resumen[i].operativo = 0;
            this.resumen[i].disponible = 0;
            this.resumen[i].fuera_operacion = 0;
            this.resumen[i].adicionales = 0;
            for (let j = 0; j < resumen.detalle_servicios.length; j++) {
              //console.log(resumen.detalle_servicios[j]);
              if (resumen.detalle_servicios[j].tipo_hora == 1) {
                switch (resumen.detalle_servicios[j].tipo_tiempo) {
                  case 1:
                    this.resumen[i].operativo += parseInt(
                      resumen.detalle_servicios[j].cant_horas
                    );
                    break;
                  case 2:
                    this.resumen[i].disponible += parseInt(
                      resumen.detalle_servicios[j].cant_horas
                    );
                    break;
                  case 3:
                    this.resumen[i].fuera_operacion += parseInt(
                      resumen.detalle_servicios[j].cant_horas
                    );
                    break;
                  default:
                    break;
                }
              } else {
                this.resumen[i].adicionales = parseInt(
                  resumen.detalle_servicios[j].cant_horas
                );
              }
            }
          }

          /* Tarifas Totales */
          for (let c = 0; c < this.detLiquidacion.length; c++) {
            let costoTrabajoTot = 0;
            let costoAdicionalTot = 0;
            this.detLiquidacion[c].Total = this.formatCurrency(
              "es-CO",
              "COP",
              2,
              this.detLiquidacion[c].valor_base_total
            );

            /* Recorrer Tarifas de la Liquidación */
            for (let i = 0; i < this.tarifaLiqui.length; i++) {
              /* Tarifas Adicionales */
              if (
                this.tarifaLiqui[i].cs_det_tarifa_type ==
                "App\\CsDetTarifasAdicionales"
              ) {
                /* Equipo */
                if (this.tarifaLiqui[i].vehiculo_equipo_type == "App\\Equipo") {
                  if (
                    this.tarifaLiqui[i].tipo_vehiculo_equipo ==
                      this.detLiquidacion[c].tipo_vehiculo_equipo &&
                    this.tarifaLiqui[i].vehiculo_equipo_id ==
                      this.detLiquidacion[c].vehiculo_equipo_id
                  ) {
                    costoAdicionalTot += parseFloat(
                      this.tarifaLiqui[i].valor_base_total
                    );
                    this.detLiquidacion[
                      c
                    ].costoAdicionalTot = this.formatCurrency(
                      "es-CO",
                      "COP",
                      2,
                      costoAdicionalTot
                    );
                  }
                }
                /* Vehículo */
                if (
                  this.tarifaLiqui[i].vehiculo_equipo_type == "App\\Vehiculo"
                ) {
                  if (
                    this.tarifaLiqui[i].tipo_vehiculo_equipo ==
                      this.detLiquidacion[c].tipo_vehiculo_equipo &&
                    this.tarifaLiqui[i].tipo_remolque ==
                      this.detLiquidacion[c].tipo_remolque &&
                    this.tarifaLiqui[i].vehiculo_equipo_id ==
                      this.detLiquidacion[c].vehiculo_equipo_id
                  ) {
                    costoAdicionalTot += parseFloat(
                      this.tarifaLiqui[i].valor_base_total
                    );
                    this.detLiquidacion[
                      c
                    ].costoAdicionalTot = this.formatCurrency(
                      "es-CO",
                      "COP",
                      2,
                      costoAdicionalTot
                    );
                  }
                }
              }
            }
          }
        });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },

    buscarBloque(search) {
      let me = this;
      if (search != "") {
        var url = "api/admin/bloques/lista?bloque_id=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.bloques = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectBloque() {
      this.form.bloque_id = this.form.bloques.map((e) => e.id);
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async selectTipoServicio() {
      this.form.tipo_vehiculo = [];
      this.form.tipo_servicio_id = this.form.tipo_servicio.map(
        (e) => e.numeracion
      );

      /* Agregar los tipos de servicios */
      if (this.form.tipo_servicio.length > 0) {
        if (this.form.tipo_servicio.some((serv) => serv.numeracion == 1)) {
          await this.getTipoVehiculos(1);
          if (this.liquidacion) {
            await this.liquidacion.cs_liquidaciones_internas_tipo_serv.forEach(
              async (t) => {
                if (t.tipo_servicio == 1) {
                  this.form.tipo_vehiculo.push(
                    this.listasForms.tipos_vehiculos.find(
                      (v) =>
                        v.tipo_vehi == t.vehiculo_equipo_id &&
                        v.id ==
                          (t.tipo_remolque != null
                            ? t.tipo_remolque
                            : t.vehiculo_equipo_id)
                    )
                  );
                }
              }
            );
          }
        }
        if (this.form.tipo_servicio.some((serv) => serv.numeracion == 2)) {
          await this.getTipoEquipo(2);
          await this.liquidacion.cs_liquidaciones_internas_tipo_serv.forEach(
            async (t) => {
              if (t.tipo_servicio == 2) {
                this.form.tipo_equipo.push(
                  this.listasForms.tipos_equipos.find(
                    (v) => v.numeracion == t.vehiculo_equipo_id
                  )
                );
              }
            }
          );
        }
      }

      /* Eliminar los tipos de servicios */
      const opcionesEliminadas = this.seleccionesPrevias.filter(
        (opcion) => !this.form.tipo_servicio.includes(opcion)
      );
      if (opcionesEliminadas.length > 0) {
        if (opcionesEliminadas.find((t) => t.numeracion == 1)) {
          this.form.tipo_vehiculo = [];
        }
        if (opcionesEliminadas.find((t) => t.numeracion == 2)) {
          this.form.tipo_equipo = [];
        }
      }
      this.seleccionesPrevias = [...this.form.tipo_servicio]; // Actualiza las selecciones previas
    },

    async getTipoVehiculos(tipo_servicio) {
      let me = this;
      const url = "api/cs/liquidacionesInternos/tipoVhEqui";
      try {
        const response = await axios.get(url, {
          params: {
            linea_negocio_id: 4,
            tipo_servicio: tipo_servicio,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        });
        let respuesta = response.data;
        me.listasForms.tipos_vehiculos = respuesta;
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Ocurrió un error" + error,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },

    selectTipoVeh() {
      this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.map((e) => e.id);
    },

    selectTipoEquipo() {
      this.form.tipo_equipo_id = this.form.tipo_equipo.map((e) => e.id);
    },

    async getTipoEquipo(tipo_servicio) {
      let me = this;
      const url = "api/cs/liquidacionesInternos/tipoVhEqui";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
            tipo_servicio: tipo_servicio,
            fecha_ini: this.form.fecha_ini,
            fecha_fin: this.form.fecha_fin,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_equipos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    async seleccionarEquipos() {
      let lista_equipos = "";
      this.listasForms.tipos_equipos.forEach((tip) => {
        lista_equipos += `<div>${tip.descripcion}</div>`;
      });

      await this.$swal({
        title: "Selección Equipos.",
        html: lista_equipos,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Si, Agregar!",
      }).then((result) => {
        if (result.value) {
          this.form.tipo_equipo = this.listasForms.tipos_equipos;
          this.form.tipo_equipo_id = this.form.tipo_equipo.map(
            (e) => e.numeracion
          );
        }
      });
    },

    limpiarEquipos() {
      this.form.tipo_equipo = [];
    },

    async seleccionarVehiculos() {
      let lista_vehiculos = "";
      this.listasForms.tipos_vehiculos.forEach((tip) => {
        lista_vehiculos += `<div>${tip.nombre}</div>`;
      });

      await this.$swal({
        title: "Selección Vehículos.",
        html: lista_vehiculos,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: "Si, Agregar!",
      }).then((result) => {
        if (result.value) {
          this.form.tipo_vehiculo = this.listasForms.tipos_vehiculos;
          this.form.tipo_vehiculo_id = this.form.tipo_vehiculo.map((e) => e.id);
        }
      });
    },

    limpiarVehiculos() {
      this.form.tipo_vehiculo = [];
    },

    async buscarEmpresas() {
      await axios
        .get("api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectEmpresa() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    async getEstados() {
      await axios.get("/api/lista/100").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validarLiquidacion() {
      axios({
        method: "GET",
        url: "/api/cs/liquidacionesInternos/valLiquidacion",
        params: this.form,
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (this.validar == "si") {
          this.$swal({
            icon: "error",
            title: `Ya existe una liquidación con los mismos parametros, ..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.bloque = {};
          this.form.bloque_id = null;
          this.form.fecha_ini = null;
          this.form.fecha_fin = null;
        }
      });
    },

    async save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: this.metodo,
          url: "/api/cs/liquidacionesInternos",
          data: this.form,
        })
          .then(async (response) => {
            if (response.data.error !== undefined) {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: response.data.error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              this.$route.params.accion = "Editar";
              this.$route.params.data_edit = response.data;
              this.metodo = "PUT";
              this.init();
              this.$swal({
                icon: "success",
                title: "Se actualizó la liquidación correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace("/Cs/LiquidacionesInternos");
    },

    getBackgroundClass(indiceResum) {
      return indiceResum % 2 === 0 ? "bg-dark" : "bg-light"; // Alterna clases de fondo en función del índice
    },

    shouldShowCell(resum, det) {
      if (resum.remolque) {
        return (
          (resum.equipo.tipo_equipo == det.tipo_vehiculo_equipo ||
            (resum.equipo.tipo_vehiculo_id == det.tipo_vehiculo_equipo &&
              resum.remolque.tipo_remolque == det.tipo_remolque)) &&
          resum.equipo_type ==
            det.vehiculo_equipo_type /*&&
          resum.equipo_id == det.vehiculo_equipo_id*/
        );
      } else {
        return (
          (resum.equipo.tipo_equipo == det.tipo_vehiculo_equipo ||
            resum.equipo.tipo_vehiculo_id == det.tipo_vehiculo_equipo) &&
          resum.equipo_type ==
            det.vehiculo_equipo_type /*&&
          resum.equipo_id == det.vehiculo_equipo_id*/
        );
      }
    },

    async printTicket(ticket) {
      let flag = true;
      if (
        !ticket.fecha_radicacion &&
        ticket.estado == 2 &&
        ticket.estado_proceso == 3
      ) {
        await this.$swal({
          title: "Está seguro de generar el Ticket?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Generar!",
        }).then((result) => {
          if (!result.value) {
            flag = false;
          }
        });
      }

      if (flag) {
        this.cargando = true;
        const data = {
          ticket_id: ticket.id,
          fecha_radicacion: ticket.fecha_radicacion,
          estado: ticket.estado,
          estado_proceso: ticket.estado_proceso,
        };
        axios
          .get(`/api/cs/liquidacionesInternos/ticket`, {
            params: data,
          })
          .then((response) => {
            let data = response.data;
            this.cargando = false;
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    async getResumenCC(id) {
      let me = this;
      const url = "api/cs/liquidacionesInternos/showCC";
      await axios
        .get(url, {
          params: {
            ticket_id: id,
          },
        })
        .then(function(response) {
          //let respuesta = response.data;
          me.pivotData = response.data;
          me.$refs.pvt.propsData.aggregatorName = "Sum";
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>
<style>
.bg-lightred {
  background-color: #ffcccc; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}

.bg-lightgreen {
  background-color: #e5f7e0; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}
.bg-lightyellow {
  background-color: #ffffcc; /* Color amarillo claro */
}
.header-resum {
  display: block;
  margin: 0px 0;
  padding: 2px;
  text-align: left;
}
.header-des {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}
.pvtTable{
  width: 100%;
}
</style>
